/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @next/next/no-img-element */
import { gql, useQuery } from '@apollo/client'
import { ON_SERVER } from 'shared/enums/environment'
import Head from 'next/head'
import React, { useEffect } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { Query_Root } from 'shared/presenters/graphqlTypes'
import {
  pageTrackerIsAllowed,
  trackPageView
} from 'shared/helpers/pageTrackerTools'

/**
 * Track user activity on the widget and eola.co.
 *
 * See below who is using each tracking tool and where:
 *
 * - Google Tag Manager:
 *   Use by: eola
 *   Where: everywhere
 *
 * - Google Analytics:
 *   Use by: outlet
 *   Where: widget
 *
 * - Facebook Pixel:
 *   Use by: outlet
 *   Where: widget
 *
 * @see Test
 * Use the following Chrome extension to test if the tracking is working:
 * GTM and GA - https://chrome.google.com/webstore/detail/tag-assistant-legacy-by-g/kejbdjndbnbjgmefkgdddjlbokphdefk
 * Facebook Pixel - https://chrome.google.com/webstore/detail/facebook-pixel-helper/fdgfkebogiimcoedlicjlajpkdmockpc
 *
 * About this approach:
 * The common approach for implementing FB and Google tracking on Next.js is to use _document.tsx
 * but that doesn't work if we need to fetch the id instead of using env variables because
 * _document.tsx only would allow it via getInitialProps and it would disable automatic
 * static optimization.
 *
 * Optimise script:
 * If you are adding a new script, please use the "strategy" property to optimise load times,
 * see https://nextjs.org/docs/basic-features/script but have in mind it has to be placed outside
 * next/head.
 */
const PageTracker = () => {
  const { query } = useRouter()
  const widgetSlug = query.widgetSlug as string

  const { data: outletData } = useQuery<Pick<Query_Root, 'widgets'>>(
    FETCH_WIDGET,
    {
      variables: {
        widgetSlug
      },
      skip: ON_SERVER || !widgetSlug || !pageTrackerIsAllowed,
      ssr: ON_SERVER
    }
  )

  const outlet = outletData?.widgets?.[0]?.outlet
  const outletFbPixelID = (outlet?.fb_pixel_id || '').replace(/[^0-9]/g, '')
  const outletGa4Id = (outlet?.google_analytics_ga4_id || '').replace(
    /[^a-zA-Z0-9-]/g,
    ''
  )

  usePageViewTracker()

  if (!pageTrackerIsAllowed) return <></>

  return (
    <>
      <Head>
        {/**
         * Facebook Pixel
         * */}
        {outlet?.fb_pixel_id && (
          <noscript>
            {/** eslint-disable-next-line @next/next/no-img-element eslint-disable-next-line jsx-a11y/alt-text */}
            <img
              height="1"
              width="1"
              style={{ display: 'none' }}
              src={`https://www.facebook.com/tr?id=${outletFbPixelID}&ev=PageView&noscript=1`}
            />
          </noscript>
        )}
      </Head>
      {/**
       * <body>
       *
       * Anything not wrapped by the <Head> component will be inserted inside the <body> tag.
       * Add here from this point below any content related to the <body> tag or a element that
       * belongs to the <head> tag but is using the <Script> component (Script is  meant to be
       * placed outside next/head).
       * */}

      {/**
       * Facebook Pixel
       * */}
      {outlet?.fb_pixel_id && (
        <Script
          id="facebook-pixel-script"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js')
                  fbq('init', ${outletFbPixelID});
                  fbq('track', 'PageView')
                `
          }}
        />
      )}

      {/**
       * Google Analytics
       * */}

      {outlet?.google_analytics_ga4_id && (
        <>
          {/* GA4 Tracking */}
          <Script
            id="google-analytics-ga4-script"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                  window.gtag = function() { dataLayer.push(arguments); }
                  window.dataLayer = window.dataLayer || [];
                  gtag('js', new Date());
                  gtag('config', '${outletGa4Id}');
                `
            }}
          />
        </>
      )}

      {/**
       * Google Tag Manager
       * */}
      <Script
        id="google-tag-manager-script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}');

            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
          `
        }}
      />
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  )
}

export default PageTracker

const FETCH_WIDGET = gql`
  query WidgetPageTrackerFetchWidget($widgetSlug: String) {
    widgets(where: { slug: { _eq: $widgetSlug } }) {
      outlet {
        name
        fb_pixel_id
        google_analytics_ga4_id
      }
    }
  }
`

/**
 * Use this hook to track route changes and send page views to Google Tag Manager, Google Analytics or Facebook Pixel.
 * See the PageTracker component to understand who is using each tracking tool.
 */
const usePageViewTracker = () => {
  const { asPath, events } = useRouter()

  // Track the first page view on initial load
  useEffect(() => {
    trackPageView(asPath, history.state?.url)
  }, [])

  const handleRouteChange = (path: string) => {
    trackPageView(path, history.state?.url)
  }

  useEffect(() => {
    events.on('routeChangeComplete', handleRouteChange)
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events])
}
